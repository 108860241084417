<script>
import { defineAsyncComponent } from 'vue'
import { useUserStore } from '@/stores/user'
import { useCustomerStore } from '@/stores/customer'
import { useZendeskStore } from '@/stores/zendesk'
import { useSalesStore } from '@/stores/sales'
import { sentryUser } from '@/plugins/sentry'
import event from '@/plugins/events'
export default {
  name: 'App',
  components: {
    DemoNotice: defineAsyncComponent(() => import('@/components/DemoNotice.vue')),
    SetTicketId: defineAsyncComponent(() => import('@/components/Tickets/SetTicketId.vue'))
  },
  data: () => ({ 
    demoMode: import.meta.env.VITE_DEMO_MODE == 'true',
    nodeEnv: import.meta.env.VITE_ENV,
    pollMinutes: import.meta.env.VITE_JWT_POLL_MINUTES || 10,
    logoUrl: '/rd-icon.png',
    showAlert: false,
    alert: {
      type: null,
      icon: null,
      title: null,
      text: null
    },
    userStore: useUserStore(),
    customerStore: useCustomerStore(),
    zendeskStore: useZendeskStore(),
    salesStore: useSalesStore(),
    rail: true,
    // npmVersion: import.meta.env.PACKAGE_VERSION,
    pinRail: false,
    showPin: false,
    showDemoNotice: false,
    refreshInterval: null,
    showSetTicketId: false,
    showSessionDetail: true,
    logoutLoading: false
  }),
  computed: {
    menuItems() {
      let items = []
      if (!this.userStore?.loggedIn) {
        items.push({
          title: 'Login',
          value: 'login',
          props: {
            prependIcon: 'mdi-login',
            link: true,
            to: { name: 'login' }
          }
        })
      } else {
        items = [
          {
            title: 'Search',
            value: 'search',
            props: {
              prependIcon: 'mdi-magnify',
              link: true,
              to: { name: 'search' }
            }
          }
        ]
        if (this.customerStore?.getCustomer) {
          items.push({
            title: 'Support',
            value: 'support',
            props: {
              prependIcon: 'mdi-account',
              link: true,
              to: { name: 'support' }
            }
          })
          items.push({
            title: 'Sales',
            value: 'sales',
            props: {
              prependIcon: 'mdi-cash-multiple',
              link: true,
              to: { name: 'sales' }
            }
          })
        }
      }
      return items
    },
    loggedIn() {
      return this.userStore?.getToken
    },
    isAdmin() {
      return this.userStore?.isAdmin
    }
  },
  watch: {
    pinRail(newVal) {
      this.userStore.setPin(newVal)
    },
    '$route.query.sessionId': {
      handler(newVal) {
        if (newVal && newVal != '') this.customerStore.setCallSessionId(newVal)
      },
      immediate: true
    },
    '$route.query.callSessionId': {
      handler(newVal) {
        if (newVal && newVal != '') this.customerStore.setCallSessionId(newVal)
      },
      immediate: true
    }
  },
  beforeMount() {
    this.pinRail = this.userStore.loggedIn ? this.userStore.getPin : false
    this.updateLogo(!this.pinRail)
  },
  async mounted() {
    if (this.userStore.loggedIn) {
      const user = this.userStore.getUser
      sentryUser({
        id: user.user_id,
        username: `${user.first_name} ${user.last_name}`,
        email: user.email
      })
      await this.refreshAuth()
      console.log('Auth Refreshed')
    }
    // this.refreshInterval = setInterval(this.refreshAuth, this.pollMinutes * 60 * 1000)
    event.on('alert', this.handleAlert)
    event.on('openSetTicketId', this.openSetTicketId)
    event.on('logout', this.logout)
    window.addEventListener('scroll', this.onScroll)
    if (this.demoMode) this.showDemoNotice = true
  },
  beforeDestroy () {
    // if (this.refreshInterval) clearInterval(this.refreshInterval)
    window.removeEventListener('scroll', this.onScroll)
    event.removeListener('alert', this.handleAlert)
  },
  methods: {
    handleAlert(data) {
      this.alert = Object.assign({}, {})
      if (typeof data == 'string') {
        this.alert.text = data
        data = {}
      } else if (data.error) {
        console.error(data.error)
        data = {
          type: 'error',
          title: data.error.error || data.title || 'Error',
          text: data.error.message || data.error
        }
        this.alert.text = data?.text || data?.textHTML;
      } else if (data.link) {
        this.alert.link = data.link
        if (!data.title && !data.titleHTML) {
          data.titleHTML = `<u>${data.text}</u>`
          data.text = null
        } else {
          data.titleHTML = data.titleHTML || `<u>${data.title}</u>`
        }
        if (!data.textHTML)
          data.textHTML = `<div width="100%" class="text-center text-caption">${
            data.text || 'click to open'
          }</div>`
      } else {
        this.alert.text = data.text
      }
      if (data.titleHTML) this.alert.titleHTML = data.titleHTML
      if (data.textHTML) this.alert.textHTML = data.textHTML
      else this.alert.title = data?.title
      this.alert.type = data?.type || 'success'
      this.alert.icon =
        data?.icon || '$' + (this.alert.type == 'error' ? 'warning' : this.alert.type)
      this.showAlert = true
      setTimeout(
        () => (this.showAlert = false),
        data?.timeout || (this.alert?.type == 'success' ? 5000 : 8000)
      )
    },
    async logout(reason) {
      this.logoutLoading = true
      try {
        if (typeof reason != 'string') reason = null
        await this.userStore.logout()
        this.customerStore.logout()
        this.zendeskStore.logout()
        this.salesStore.logout()
        if (!reason) this.handleAlert('Logout Successful')
        sentryUser(null)
      } catch (e) {
        event.error(e)
      }
      let route = { name: 'login', query: this.$route.query || {} }
      if (reason) route.query.error = reason
      this.logoutLoading = false
      this.$router.push(route)
    },
    updateLogo(rail) {
      this.logoUrl = rail ? '/rd-icon.png' : '/rd-logo.png'
      this.showPin = !rail
    },
    openAlertLink() {
      if (this.alert.link) {
        window.open(this.alert.link)
        this.showAlert = false
      }
    },
    openSetTicketId() {
      this.showSetTicketId = true
    },
    async refreshAuth() {
      console.log(`LOGGED IN: ${this.userStore.loggedIn}`)
      if (this.userStore.loggedIn) {
        try {
          const { token } = await this.$axios.post('/login/refresh', null, {
            headers: {
              'x-api-key': import.meta.env.VITE_ADMIN_API_KEY,
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          })
          console.log(`Assigning new auth token`)
          this.$axios.defaults.headers = { Authorization: `Bearer ${token}` }
          this.userStore.setToken(token)
        } catch (e) {
          console.error(`REFRESH AUTH ERROR:`, e)
          this.logout('Authorization Expired')
        }
      }
    },
    onScroll () {
      let currentScrollPosition = window.scrollY || (import.meta.env.VITE_ENV != 'test' ? document.documentElement.scrollTop : 0)
      if (currentScrollPosition < 0) return
      this.showSessionDetail = currentScrollPosition == 0
    },
    clearCustomer() {
      this.$router.push({name: 'search'})
      this.customerStore.logout()
    },
    clearCart() {
      this.salesStore.clearCart()
    }
  }
}
</script>

<template lang="pug">
v-app#app
  v-navigation-drawer(
    width="200", permanent,
    :rail="!pinRail",
    :expand-on-hover="!pinRail",
    @update:rail="updateLogo"
  )
    v-btn.pin-btn(v-show="showPin", :icon="pinRail?'mdi-pin':'mdi-pin-outline'", size="x-small", @click="pinRail=!pinRail", variant="text", color="primary")
    v-img.my-8.mx-auto(:src="logoUrl", max-width="200" height="30")
    v-divider.mb-4
    .m-0.p-0.d-flex.align-center(style="height:65%")
      v-list(width="100%")
        v-list-item(v-for="i in menuItems", :key="i.value", :to="i.props.to", link, :prepend-icon="i.props.prependIcon", :title="i.title")
        div.fixed-bottom
          v-list-item(v-if="isAdmin", link, :to="{name: 'admin'}", prepend-icon="mdi-cog-outline", title="Admin")
          v-list-item(v-if="loggedIn", @click="logout", prepend-icon="mdi-logout", title="Logout")

  v-main
    router-view(:fluid="!pinRail")

  v-fade-transition
    .top-right.text-right.text-caption(v-show="showSessionDetail")
      div(v-show="userStore.getUserName") {{ userStore.getUserName }}
      div(v-show="customerStore.getCustomer && !['support','sales'].includes($route.name)")
        b Customer: 
        | {{ customerStore.getName }}
      div(v-show="customerStore.getCallSessionId")
        b Call Session ID: 
        | {{ customerStore.getCallSessionId }}
      div(v-show="customerStore.getQueue")
        b Queue: 
        | {{ customerStore.getQueue }}
      div(v-show="userStore.loggedIn")
        b {{ zendeskStore.getTechTicketId ? 'Sales Ticket' : 'Ticket' }} ID: 
        span(v-if="zendeskStore.getTicketId")
          a(:href="this.zendeskStore.getTicketUrl()", target="_blank") {{ zendeskStore.getTicketId }}
          v-icon.ml-1(icon="mdi-pencil", @click="openSetTicketId", size="x-small", color="black")
        a(v-else href="", @click.prevent="openSetTicketId") Assign
      .font-weight-bold(v-if="demoMode") DEMO MODE
      div(v-show="zendeskStore.getTechTicketId").mr-4
        b Tech Ticket ID: 
        span
          a(:href="this.zendeskStore.getTicketUrl(zendeskStore.getTechTicketId)", target="_blank") {{ zendeskStore.getTechTicketId }}
      .font-weight-bold(v-if="demoMode") DEMO MODE
      //- v-btn.mt-2(v-if="nodeEnv == 'development' && customerStore.getCustomer", text="Clear Customer", @click="clearCustomer", color="error", size="x-small", variant="text")
      //- div(v-if="nodeEnv == 'development' && salesStore.getCartCount")
        v-btn(text="Clear Cart", @click="clearCart", color="error", size="x-small", variant="text")


  //- .bottom-right.text-caption v{{ npmVersion }}

  v-fade-transition
    v-alert.mt-1.mx-auto(
      v-model="showAlert"
      :icon="alert.icon"
      :type="alert.type"
      @click="openAlertLink"
      closable
    )
      template(v-slot:title)
        div(:class="[alert.link ? 'pointer' : '']")
          span(v-if="alert.titleHTML" v-html="alert.titleHTML")
          span(v-else) {{ alert.title }}
      template(v-slot:text)
        div(:class="[alert.link ? 'pointer' : '']")
          span(v-if="alert.textHTML" v-html="alert.textHTML")
          span(v-else) {{ alert.text }}

  v-dialog(v-model="showDemoNotice")
    demo-notice.mx-auto(width="50%", @close="showDemoNotice = false")

  v-dialog(v-model="showSetTicketId", persistent)
    set-ticket-id.mx-auto(width="33%", @close="showSetTicketId = false")

  v-overlay.justify-center.align-center(:model-value="logoutLoading")
    v-progress-circular(color="primary", indeterminate, size="64")
  
</template>

<style>
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fixed-bottom {
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}
.v-alert {
  position: fixed;
  left: 50%;
  top: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 999999;
}
.notice-msg {
  left: 0% !important;
  bottom: 0% !important;
}

.top-right {
  position: fixed;
  right: 0px;
}
.bottom-right {
  position: fixed;
  bottom: 0px;
  right: 0px;
}
.pin-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.no-decoration {
  text-decoration: none;
}
.front {
  z-index: 99999;
}
</style>