import axios from 'axios'
import {useUserStore} from '@/stores/user'
import event from '@/plugins/events'

export const api = axios.create({
  baseURL: '/api'
})

if (process.env.NODE_ENV != 'test') {
  api.interceptors.response.use(function (response) {
    return response.data
  }, function (error) {
    if (error.response.status == 401) event.emit('logout', 'Authorization Expired')
    return Promise.reject(error.response.data || error.response)
  })
}

export default {
  install: (app, options) => {
      const userStore = useUserStore()
      if (userStore.getToken) {
        console.log("Using cached auth token")
        api.defaults.headers = {'Authorization': `Bearer ${userStore.getToken}`}
      }
      app.config.globalProperties.$axios = api
  }
}