import {init, captureException, captureMessage, browserTracingIntegration, replayIntegration, feedbackIntegration, setUser } from '@sentry/vue'

export const SENTRY_ENABLED = import.meta.env.VITE_SENTRY_ENABLED?.toLowerCase() == 'true'

export const sentryInit = (app, router) => {
  if (SENTRY_ENABLED) {
    init({
      app,
      environment: import.meta.env.VITE_ENV,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      tunnel: '/api/sentry',
      ignoreErrors: [
        'Failed to fetch dynamically imported module', 'FST_JWT_AUTHORIZATION_TOKEN_EXPIRED',
        'Email not found', 'Invalid Password', 'Your One-Time Passcode is invalid'
      ],
      integrations: [
        browserTracingIntegration({ router }),
        replayIntegration({
          networkDetailAllowUrls: [window.location.origin],
        }),
        feedbackIntegration({
          showName: false,
          showEmail: false,
          colorScheme: 'system',
        })
      ],
      tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE || 0.1,
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/cash\.ustechsupport\.com\/api/,
        /^https:\/\/cash-stage\.ustechsupport\.com\/api/,
        /^https:\/\/cash-demo\.ustechsupport\.com\/api/
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    })
  }
}

export const sentryException = data => SENTRY_ENABLED ? captureException(data) : null
export const sentryMessage = data => SENTRY_ENABLED ? captureMessage(data) : null
export const sentryUser = data => SENTRY_ENABLED ? setUser(data) : null