import { defineStore } from 'pinia'
import { obj2Array } from '@/plugins/util'
import event from '@/plugins/events'
const {localStorage, sessionStorage} = window

const itemTemplate = {
  offer_component_id: null,
  offer_configuration_id: null,
  transaction_type: null,
  product_size: null,
  initial_term: null,
  price: null,
  msrp: null,
  renewal_term: null,
  renewal_price: null,
  cart_id: null
}

export const useSalesStore = defineStore('sales', {
  state: () => ({
    cart: JSON.parse(sessionStorage.getItem('SalesCart') || '{}'),
    maxId: sessionStorage.getItem('SalesCartMaxId') || 0,
    basket: JSON.parse(sessionStorage.getItem('SalesBasket') || null),
    products: JSON.parse(localStorage.getItem('Products') || '[]'),
    benefits: JSON.parse(localStorage.getItem('Benefits') || '{}'),
    salesTransition: sessionStorage.getItem('SalesTransition'),
    salesOfferId: sessionStorage.getItem('SalesOfferId'),
    noOfferReason: sessionStorage.getItem('NoOfferReason'),
    overlappingProducts: JSON.parse(localStorage.getItem('OverlappingProducts') || null),
    productNameConverter: JSON.parse(localStorage.getItem('ProductNameConverter') || null),
    productAcronymConverter: JSON.parse(localStorage.getItem('ProductAcronymConverter') || null),
    productUpgrades: JSON.parse(localStorage.getItem('ProductUpgrades') || null)
  }),
  getters: {
    getCart: ({cart}) => cart,
    getCartList: ({cart}) => obj2Array(cart),
    getCartCount: ({cart}) => Object.keys(cart).length,
    getBasket: ({basket}) => basket,
    getBasketToken: ({basket}) => basket?.token,
    getProducts: ({products}) => products,
    getProductUpgrades: ({productUpgrades}) => productUpgrades,
    getStandardPrice: ({cart}) => parseFloat(Object.values(cart).reduce((sum, c) => sum += (parseFloat(c.price) || 0), 0).toFixed(2)),
    getTotalMSRP: ({cart}) => parseFloat(Object.values(cart).reduce((total, c) => total += (parseFloat(c.msrp) || 0), 0).toFixed(2)),
    getBenefits: ({benefits}) => benefits,
    haveBenefits: ({benefits}) => Object.keys(benefits).length,
    getSalesTransition: ({salesTransition}) => salesTransition,
    getSalesOfferId: ({salesOfferId}) => salesOfferId,
    getNoOfferReason: ({noOfferReason}) => noOfferReason
  },
  actions: {
    findProduct(sub) {
      let pc = sub.product_category
      if (sub.transaction_type == 'Renew' && sub.product_line_category == 'Tech Support' && sub.product_category != 'LiveTech') pc = 'LiveTech'
      let item = this.products.find(p => p.transaction_type == sub.transaction_type
                                          && p.product_category == pc
                                          && p.initial_term == sub.initial_term
                                          && p.product_size == sub.product_size)
      if (!item) {
        let msg = `No matching product found for cart entry: ${JSON.stringify(sub)}`
        event.error(msg)
      }
      return {...item}
    },
    addItem(sub) {
      // console.log('Adding item to cart', sub)
      if (!sub) return
      let item = this.findProduct(sub)
      const cart_id = ++this.maxId
      const tmp = {}
      const {transaction_type, order_id, product_id} = sub
      tmp[cart_id] = {cart_id, ...item, transaction_type}
      if (item.stop_subscription) tmp[cart_id].stop_subscription_id = `${order_id}.${product_id}`
      this.cart = Object.assign(this.cart, tmp)
      sub = Object.assign(sub, tmp[cart_id])
      this.saveCart()
      return cart_id
    },
    updateItem(item={}) {
      if (!item.transaction_type) return
      // console.log(`Updating cart item`, item)
      if (!item.cart_id) return console.error(`Attempted to update Sales Cart store with an invalid item`, item)
      let offer = this.findProduct(item)
      this.cart[item.cart_id] = Object.assign(this.cart[item.cart_id], offer)
      item = Object.assign(item, this.cart[item.cart_id])
      this.saveCart()
    },
    removeItem(sub) {
      if (!sub || !sub.cart_id) return
      delete this.cart[sub.cart_id]
      this.saveCart()
      sub = Object.assign(sub, itemTemplate)
    },
    saveCart() {
      sessionStorage.setItem('SalesCart', JSON.stringify(this.cart))
      sessionStorage.setItem('SalesCartMaxId', this.maxId)
    },
    setProducts(products) {
      this.products = products
      products ? localStorage.setItem('Products', JSON.stringify(this.products)) : localStorage.removeItem('Products')
    },
    setBenefits(benefits) {
      if (benefits) {
        const final = {}
        benefits.forEach(b => final[b.product_category] = b.benefits)
        this.benefits = Object.assign({}, final)
        localStorage.setItem('Benefits', JSON.stringify(this.benefits))
      } else {
        this.benefits = Object.assign({}, {})
        localStorage.removeItem('Benefits')
      }
    },
    getProductBenefits(product_category) { return this.benefits[product_category] },
    setBasket(basket) {
      if (basket) {
        console.log(`New Basket`, basket)
        this.basket = Object.assign({}, basket)
        sessionStorage.setItem('SalesBasket', JSON.stringify(this.basket))
      } else {
        this.basket = null
        sessionStorage.removeItem('SalesBasket')
      }
    },
    setSalesTransition(val) {
      if (val) {
        this.salesTransition = val
        sessionStorage.setItem('SalesTransition', val)
      } else {
        this.salesTransition = null
        sessionStorage.removeItem('SalesTransition')
      }
    },
    setSalesOfferId(val) {
      if (val) {
        this.salesOfferId = val
        sessionStorage.setItem('SalesOfferId', val)
      } else {
        this.salesOfferId = null
        sessionStorage.removeItem('SalesOfferId')
      }
    },
    setNoOfferReason(val) {
      if (val) {
        this.noOfferReason = val
        sessionStorage.setItem('NoOfferReason', val)
      } else {
        this.noOfferReason = null
        sessionStorage.removeItem('NoOfferReason')
      }
    },
    getOverlappingProducts() {
      if (this.overlappingProducts) return this.overlappingProducts
      const o = {
        LiveTech: ['LiveTech with Dark Web','System Mechanic Pro & LiveTech','System Mechanic Standard & LiveTech','System Mechanic Ultimate Defense & LiveTech','Support','SmartSupport','SDCTS'],
        'System Mechanic Ultimate Defense': ['System Mechanic Standard','System Mechanic Standard & Hitman Pro','System Mechanic Standard & IPS','System Mechanic Standard & LiveTech',
                                              'System Mechanic Standard & NovaBACKUP','System Mechanic Standard & Search and Recover','System Mechanic Standard & System Shield','System Mechanic Pro',
                                              'System Mechanic Pro & IPS','System Mechanic Pro & LiveTech','System Mechanic Business','System Mechanic Business & DriveScrubber','System Mechanic Premium',
                                              'System Mechanic Ultimate Defense & LiveTech','Malware Killer','System Shield','IPS & System Shield','VirusFix','Antivirus','Antimalware',
                                              'System Mechanic Total Care', 'Hitman Pro', 'FixMeStick', 'Bitdefender', 'Live Antivirus', 'Live Antimalware', 'Live Antivirus & Antimalware', 'Live Antivirus & EasyBackupPro'],
        'System Mechanic Standard': ['System Mechanic Ultimate Defense','System Mechanic Standard & Hitman Pro','System Mechanic Standard & IPS','System Mechanic Standard & LiveTech','System Mechanic Standard & NovaBACKUP',
                                      'System Mechanic Standard & Search and Recover','System Mechanic Standard & System Shield','System Mechanic Total Care','System Mechanic Pro',
                                      'System Mechanic Pro & IPS','System Mechanic Pro & LiveTech','System Mechanic Business','System Mechanic Business & DriveScrubber','System Mechanic Premium',
                                      'System Mechanic Ultimate Defense & LiveTech','MyCleanPC','MyCleanMac'],
        WarrantyStar: [],
        GetMyDrivers: [],
        IDProtect: ['SAS ID Protect', 'Identity Force'],
        MyCleanID: ['Privacy Guardian'],
        OnlineBackup: ['EasyBackupPro'],
        VPN: ['HotspotShield'],
      }
      for (const k in o) {
        const oCount = o[k].length
        for (let i = 0; i < oCount; i++) {
          o[o[k][i]] = o[k]
          o[o[k][i]].push(k)
        }
      }
      this.overlappingProducts = o
      localStorage.setItem('OverlappingProducts', JSON.stringify(this.overlappingProducts))
      return this.overlappingProducts
    },
    getProductNameConverter() {
      if (this.productNameConverter) return this.productNameConverter
      this.productNameConverter = {
        'LiveTech': 'LT', 'System Mechanic Ultimate Defense': 'SMUD', 
        'WarrantyStar': 'WS', 'GetMyDrivers': 'GMD', 'VPN': 'VPN',
        'IDProtect': 'IDP', 'MyCleanID': 'MCID', 'OnlineBackup': 'OB'
      }
      localStorage.setItem('ProductNameConverter', JSON.stringify(this.productNameConverter))
      return this.productNameConverter
    },
    getProductAcronymConverter() {
      if (this.productAcronymConverter) return this.productAcronymConverter
      const pn = this.getProductNameConverter()
      const p = {}
      for (const k in pn) p[pn[k]] = k
      this.productAcronymConverter = p
      localStorage.setItem('ProductAcronymConverter', JSON.stringify(this.productAcronymConverter))
      return this.productAcronymConverter
    },
    setProductUpgrades(products) {
      if (products) {
        this.productUpgrades = products
        localStorage.setItem('ProductUpgrades', JSON.stringify(this.productUpgrades))
      } else {
        this.productUpgrades = []
        localStorage.removeItem('ProductUpgrades')
      }
    },
    clearProductData() {
      this.setProducts()
      this.setBenefits()
      this.productNameConverter = null
      localStorage.removeItem('ProductNameConverter')
      this.productAcronymConverter = null
      localStorage.removeItem('ProductAcronymConverter')
      this.overlappingProducts = null
      localStorage.removeItem('OverlappingProducts')
      this.productUpgrades = null
      localStorage.removeItem('ProductUpgrades')
    },
    clearSalesOffer() {
      this.setSalesOfferId()
      this.setSalesTransition()
      this.setNoOfferReason()
    },
    clearCart() {
      this.cart = Object.assign({}, {})
      this.maxId = 0
      sessionStorage.removeItem('SalesCart')
      sessionStorage.removeItem('SalesCartMaxId')
    },
    clearCustomer() {
      this.setBasket()
      this.clearCart()
      this.clearSalesOffer()
    },
    logout() {
      this.clearCustomer()
      this.clearProductData()
    }
  }
})