export const obj2Array = (obj) => Object.entries(obj).map(([id, item]) => item)

export const array2Obj = (array, id='id', removeId=false) => {
  const final = {}
  array.forEach(a => {
    aid = a[id]
    if (removeId) delete a.id
    final[aid] = a
  })
  return final
}

export const sortObjs = (array, field, desc) => {
  let list = array
  list.sort(function(a, b) { 
    return desc ? b[field] - a[field] : a[field] - b[field]
  })
  return list
}